/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.purchaseCardWrap {
  width: 100%;
  margin-top: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >div {
    flex: 1;

    .image {
      width: 100%;
      height: auto;
    }
  }

  .purchaseCardImage {
    margin-right: 0;
  }
  
  .purchaseCardInfo {
    margin-top: 32px;
    .purchaseCardInfoTitle {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      color: $primary-text;
      width: 100%;
      text-align: center;
    }
    .purchaseCardInfoDescription {
      font-size: 18px;
      line-height: 26px;
      color: $secondary-text;
      margin-top: 16px;
    }
    .purchaseCardInfoButton {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      color: $brand;
      margin-top: 8px;
      cursor: pointer;
      width: 100%;
      text-align: center;

      .icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }

  
}

@media screen and (min-width: $divide-992) {
  .purchaseCardWrap {
    margin-top: 80px;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    .purchaseCardInfo {
      margin-top: 0;
      .purchaseCardInfoTitle {
        text-align: start;
      }
      .purchaseCardInfoButton {
        text-align: start;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .purchaseCardImage {
      margin-right: 100px;
    }
  }
}