/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.cardList{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 32px;
  row-gap: 16px;
  column-gap: 16px;
}

@media screen and (min-width: $divide-992) {
  .cardList{
    grid-template-columns: repeat(2, 1fr);
    margin-top: 80px;
    row-gap: 72px;
    column-gap: 128px;
  }
}