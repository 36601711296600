/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.cardContainer {
  padding: 24px;
  border-radius: 12px;
  background-color: #24242C;
  display: flex;
  align-items: center;
  gap: 24px;
  .img{
    width: 48px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .textWrap{
    display: flex;
    flex-direction: column;
    gap: 4px;
    .title{
      color: $white-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .content{
      color: $secondary-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

@media screen and (min-width: $divide-992) {
  .cardContainer{
    padding: 35px 24px;
  }
}