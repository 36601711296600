/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/constants.scss';
@import '/src/assets/theme/color.scss';

.navHeader {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navBlackHeader {
  background: $page-bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navSticky {
  // position: '-webkit-sticky'; // don't effect
  // position: sticky; // don't effect
  // top: 0;
  // z-index: 1;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}

.linkBtnWrap {
  display: inline-block;
  position: relative;
}

.btnUnderline {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  width: 20px;
  height: 10px;
  margin: auto;
  svg path {
    fill: var(--firstMenuFontHoverColor);
  }
}

.actionButton {
  font-size: 16px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  cursor: pointer;
  border: 1px solid;
  &:hover {
    opacity: 0.8;
  }
}

.menuIcon {
  display: none;
  svg path {
    stroke: $primary-text;
  }
}

.menus {
  display: flex;
  align-items: center;
  gap: 64px;

  .firstMenuWithChild {
    cursor: pointer;
    svg {
      margin-left: 12px;
      @include rotate(0, 0.2s);
      path {
        fill: var(--firstMenuDefaultFontColor);
      }
    }

    &:hover {
      svg path {
        fill: var(--firstMenuFontHoverColor);
      }
    }
  }

  .rotateSvg {
    svg {
      @include rotate(180deg, 0.2s);
    }
  }
}

.secondMenus {
  :global {
    .ant-popover-arrow {
      top: 1px;
    }
    .ant-popover-arrow-content {
      box-sizing: content-box;
      border: 1.5px solid var(--border) !important;
    }
    .ant-popover-arrow-content::before {
      background-color: var(--normalBg) !important;
    }
    .ant-popover-inner-content {
      background-color: var(--normalBg) !important;
      border-radius: 6px;
      border: 1px solid var(--border) !important;
      overflow: hidden;
    }
    .ant-popover-inner {
      background: none !important;
      overflow: hidden !important;
      border-radius: 6px;
    }
  }
  .secondMenuList {
    width: 100%;
    .secondMenuItem {
      width: 100%;
      padding: 8px 12px;
      font-weight: 500;
      font-size: 16px;
      color: $primary-text;
      border-radius: 2px;
      margin-bottom: 4px;
      cursor: pointer;

      &:hover {
        color: $brand;
        background: $hover-bg;
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $divide-991) {
  .menuIcon {
    display: block;
  }
  .menus {
    display: none;
  }
}
