/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.tabsModuleWrap {
  background: $page-bg;
  display: flex;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    max-width: $divide-1080;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tabsModuleRadioWrap {
    width: 400px;
    height: 48px;
    border: 1px solid $brand;
    border-radius: 12px;
    padding: 4px;

    :global {
      .ant-radio-group  {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: inherit;

        .ant-radio-button-wrapper {
          width: 50%;
          height: 100%;
          line-height: 1;
          background: inherit;
          color: $brand;
          border: none;
          border-radius: 12px;

          &::before {
            width: 0;
          }

          >span {
            background-color: inherit;
            border-radius: 12px;
          }

          >span:last-child {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
          }
        }

        .ant-radio-button-wrapper-checked {
          background-color: $brand;
          color: $primary-text;
        }
      }
    }
    
  }

  .contentWrap {
    width: 100%;
    height: max-content;
  }

  @media screen and (max-width: $divide-991) {
    .title {
      font-size: 30px;
      line-height: 38px;
    }

    .content {
      margin-top: 40px;
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: $divide-991) and (min-width: $divide-768) {
    .container {
      width: 728px;
    }
    .tabsModuleRadioWrap {
      width: 100%;
      height: 48px;
    }
  }

  @media screen and (max-width: $divide-767) and (min-width: $divide-580) {
    .container {
      width: 540px;
    }
    .tabsModuleRadioWrap {
      width: 100%;
      height: 48px;
    }
  }

  @media screen and (max-width: $divide-579) {
    .tabsModuleRadioWrap {
      width: 100%;
      height: 48px;
    }
  }
}
