/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.tabsImageWrap {
  width: 100%;
  margin-top: 32px;
  position: relative;

  .image {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: $divide-992) {
  .tabsImageWrap {
    margin-top: 80px;
  }
}