/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.lineChartWrap {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .chartWrap {
    width: 100%;
    height: 210px;
    position: relative;
    .chartCanvas {
      width: 100%;
      height: 100%;
    }
  }
  .dataListWrap {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    .dataListCard {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dataListValue {
        color: $primary-text;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
      }
      .dataListLabel {
        color: $secondary-text;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (min-width: $divide-992) {
  .lineChartWrap {
    margin-top: 80px;
    flex-direction: row;
    .chartWrap {
      width: 462px;
      height: 289px;
    }
    .dataListWrap {
      width: 400px;
      flex-direction: column;
      margin-top: 0;
      justify-content: center;
      .dataListCard {
        margin-bottom: 32px;
        align-items: center;
        flex-direction: column;
        &:last-child {
          margin-bottom: 0;
        }
        
        .dataListValue {
          color: $primary-text;
          font-size: 32px;
          line-height: 48px;
          font-weight: 600;
        }
        .dataListLabel {
          color: $secondary-text;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin-top: 16px;
        }
      }
    }
    
  }
  
}